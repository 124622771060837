import React from 'react'
import HeroLayout from '../components/heroLayout'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import styles from './Donate.module.css'
import Layout from '../components/layout'

class Donate extends React.Component {
    render() {
      const siteTitle = get(this, 'props.data.site.siteMetadata.title')      
      const [layoutHeroImage] = get(this, 'props.data.allContentfulLayoutHeroImage.edges')      
  
      return (
        <Layout location={this.props.location}>
          <div style={{ background: '#fff' }}>
            <Helmet title={siteTitle} />
            <HeroLayout data={layoutHeroImage.node} />            
            <div className="wrapper">
              <h2 className="section-headline">Donate</h2>
              <strong>Donations can be made into the account of “Helderberg Outreach”, with a clear reference: Truth2kids:</strong>
              <figure class="quote">
              <blockquote>
                <u><strong>H</strong>elderberg <strong>O</strong>utreach</u><br/><br/>
                <strong>Bank</strong>: ABSA<br/>
                <strong>Branch</strong>: Somerset-West<br/>
                <strong>Account no</strong>: 9114 483 080<br/>
                <strong>Branch Code</strong>: 632005<br/>                 
              </blockquote>
              <figcaption>
                <strong>Reference</strong>:Truth2kids
              </figcaption>
              </figure>              
              <p>
                Helderberg Outreach is registered in terms of Section 18A of the Income Tax Act and can issue receipts for tax deduction for
                donors donating for social upliftment and development projects. Financial audits are done on a regular basis.
              </p>
              <p>
                Please submit proof of your donation to <strong>Truth2kids</strong>, via the following email address <strong>truth2kids@gmail.com</strong> for your Section 18A
                receipt should you require such a receipt for tax purposes.
              </p>
            </div>
          </div>
        </Layout>
      )
    }
  }
  
  export default Donate

  export const donateQuery = graphql`
  query DonateQuery {
    allContentfulLayoutHeroImage(filter: {title: {regex: "/Homepage 8/"}}, limit: 1) {
      edges {
        node {
          title
          headline
          backgroundImage {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid
            }          
          }
        }
      }
    }
  }  
`
